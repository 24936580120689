#flipboxcontainer {
  overflow: hidden;

  @media (max-width:767px) {
    display: none;
  }

  .flip-container {
    perspective: 1000px;
    height: 270px;

    &:hover,
    .hover {
      .flipper {
        transform: rotateY(180deg);
      }
    }

    .flipper {
      transition: 0.6s;
      transform-style: preserve-3d;
      position: relative;

      .front,
      .back {
        background: $primary-color;
        border-radius: 5px;
        height: 270px;
        width: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
      }

      .front {
        z-index: 2;
        transform: rotateY(0deg);

        img {
          margin: 22px auto;
          display: block;
        }

        h2 {
          text-align: center;
          color: $secondary-color;

          @media (max-width:991px) {
            //font-size: 1.5rem;
          }
        }
      }

      .back {
        transform: rotateY(180deg);

        p {
          font-size: 20px;
          margin: 25px;
          color: $secondary-color;
          text-align: center;

          @media (max-width:991px) {
            font-size: 18px;
            margin: 10px 15px;
          }
        }

        .hom-box-btn {
          display: inline-block;
          padding: 10px 20px;
          font-weight: 600;
          border-radius: 10px;
          background: $secondary-color;
          color: $primary-color;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}